let Lockr = require("lockr");
Lockr.setPrefix("ic4ba_");

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? ""
    : `${window.location.protocol}//${window.location.hostname}`;
export const getContactUsUrl = () => `${BASE_URL}/api/v1/utility/contact-us`;

export const getSuscribeUrl = () => `${BASE_URL}/api/v1/utility/subscribe`;

const getOTPURL = (mobileNumber) =>
  `${BASE_URL}/api/v1/users/register/otp/${mobileNumber}`;
const getMobileVerifyURL = () => `${BASE_URL}/api/v1/users/register/otp/verify`;
const getDoctorClinicURL = () => `${BASE_URL}/api/v1/users/register/settings`;

const getAvailableSlotsURL = (clinicId, doctorId, attendeeId, isReschedule) => {
  const queryParams = [
    `days=60`,
    `spaceId=${clinicId}`,
    `ownerId=${doctorId}`,
    `fromDate=${new Date().toISOString().split("T")[0]}`,
  ];

  if (attendeeId) {
    queryParams.push(`attendeeId=${attendeeId}`);
  }

  if (isReschedule) {
    queryParams.push(`reschedule=true`);
  }

  return `${BASE_URL}/api/v1/appointments/slots?${queryParams.join("&")}`;
};

const bookAppointmentURL = (isOnline) => {
  console.log(Lockr.get("timeSlot").clinicId);

  const clinicId = isOnline
    ? Lockr.get("timeSlot").clinicId
    : Lockr.get("cid", "CC000001");

  return `${BASE_URL}/api/v1/appointments/${clinicId}`;
};
const createProfileURL = () => `${BASE_URL}/api/v1/users/register/web`;
const getUpdatePatientDoctorAndClinicURL = (patientId) =>
  `${BASE_URL}/api/v1/users/profile/${patientId}`;
const updateNoteURL = (patientId) =>
  `${BASE_URL}/api/v1/users/profile/${patientId}`;
const bookWaitlistURL = () => {
  const clinicId = Lockr.get("cid", "CC000001");
  return `${BASE_URL}/api/v1/waiting/${clinicId}`;
};
const loginURL = () => `${BASE_URL}/api/v1/users/login`;
const getProfileURL = (patientId) =>
  `${BASE_URL}/api/v1/users/profile/${patientId}`;

const getUpcomingAppointmentsURL = (patientId) =>
  `${BASE_URL}/api/v1/appointments?attendeeId=${patientId}`;
const getPastAppointmentsURL = (patientId) =>
  `${BASE_URL}/api/v1/appointments/past?attendeeId=${patientId}`;
const getCancelAppointmentURL = (apptId) =>
  `${BASE_URL}/api/v1/appointments/${apptId}/cancel`;
const getConfirmAppointmentAttendanceURL = (apptId) =>
  `${BASE_URL}/api/v1/appointments/${apptId}/attend`;
const getRescheduleAppointmentURL = (appId, newSlotId, clinicId) =>
  `${BASE_URL}/api/v1/appointments/${appId}/reschedule?slotId=${newSlotId}&spaceId=${clinicId}`;

const getForgotPasswordURL = (pid) =>
  `${BASE_URL}/api/v1/users/forget/${pid}/otp`;
const getForgotPasswordOTPVerifyURL = (pid) =>
  `${BASE_URL}/api/v1/users/forget/${pid}/otp/verify`;
const getPasswordSetupURL = () => `${BASE_URL}/api/v1/users/password`;
const getTokenURL = () => `${BASE_URL}/api/v1/users/register/web/token`;

const getAppointmentDetailsURL = (appointmentId) =>
  `${BASE_URL}/api/v1/appointments/${appointmentId}/info`;

export const getToken = () => {
  return fetch(getTokenURL(), { method: "GET" })
    .then((response) => response.json())
    .then((data) => Lockr.set("creds", { tkn: data.token }));
};

export const sendOTP = (mobile) => {
  return fetch(getOTPURL(mobile), { method: "POST" }).then((response) =>
    response.json()
  );
};

export const verifyMobile = (otpValue) => {
  return fetch(getMobileVerifyURL(), {
    method: "POST",
    headers: { "Content-Type": "application/json;charset=utf-8" },
    body: JSON.stringify({ tid: Lockr.get("ttkn"), otp: otpValue }),
  }).then((response) => response.json());
};

export const getAvailableSlots = (clinicId, doctorId, isReschedule) => {
  const attendeeId = Lockr.get("creds")["uid"];
  return fetch(
    getAvailableSlotsURL(clinicId, doctorId, attendeeId, isReschedule),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${Lockr.get("creds")["tkn"]}`,
      },
    }
  ).then((response) => response.json());
};

export const bookAppointment = () => {
  const _attendeeId = Lockr.get("creds")["uid"];
  const _isOnline = Lockr.get("isOnline", false);
  const _slotId = Lockr.get("timeSlot")["slotId"];
  return fetch(bookAppointmentURL(_isOnline), {
    method: "POST",
    body: JSON.stringify({
      slotId: _slotId,
      attendeeId: _attendeeId,
      ownerId: Lockr.get("did", "DR000001"),
      source: "WEB",
      online: _isOnline,
    }),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${Lockr.get("creds")["tkn"]}`,
    },
  }).then((response) => response.json());
};

export const createProfile = () => {
  const name = Lockr.get("name");
  return fetch(createProfileURL(), {
    method: "POST",
    body: JSON.stringify({
      tid: Lockr.get("ttkn"),
      firstName: name.firstName,
      middleName: name.middleName,
      lastName: name.lastName,
      email: Lockr.get("email")["email"],
      mobileCode: Lockr.get("phoneDialCode", "+91"),
      healthProviderId: Lockr.get("did"),
      clinicId: Lockr.get("cid"),
      //'email': Lockr.get('email')['email'],
      //'note': Lockr.get("note", {note: "to be added"})["note"]
    }),
    headers: { "Content-Type": "application/json;charset=utf-8" },
  }).then((response) => response.json());
};

export const updatePatientsDoctorAndClinicId = (cid, did) => {
  const _attendeeId = Lockr.get("creds")["uid"];
  return fetch(getUpdatePatientDoctorAndClinicURL(_attendeeId), {
    method: "PATCH",
    body: JSON.stringify({
      clinicId: cid,
      doctorId: did,
    }),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${Lockr.get("ttkn")}`,
    },
  }).then((response) => response.json());
};

export const updateNote = () => {
  const _attendeeId = Lockr.get("creds")["uid"];
  return fetch(updateNoteURL(_attendeeId), {
    method: "PATCH",
    body: JSON.stringify({
      note: Lockr.get("note")["note"],
    }),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${Lockr.get("creds")["tkn"]}`,
    },
  }).then((response) => response.json());
};

export const bookWaitlist = () => {
  const _attendeeId = Lockr.get("creds")["uid"];
  const _date = Lockr.get("avlTimeSlots")["date"];
  return fetch(bookWaitlistURL(), {
    method: "POST",
    body: JSON.stringify({
      date: _date,
      attendeeId: _attendeeId,
      ownerId: Lockr.get("did", "DR000001"),
      location: "NA",
      note: "Website booking",
      source: "WEB",
    }),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${Lockr.get("creds")["tkn"]}`,
    },
  }).then((response) => response.json());
};

export const parsePID = (uid) => {
  return uid.replace(/-/g, "").replace(/\s/g, "");
};

export const login = (pid, password) => {
  return fetch(loginURL(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      patientId: parsePID(pid),
      password: password,
    }),
  }).then((response) => response.json());
};

export const getProfile = (pid, token) => {
  return fetch(getProfileURL(parsePID(pid)), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((response) => response.json());
};

export const getDoctorsClinicList = () => {
  return fetch(getDoctorClinicURL(), {
    method: "GET",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Lockr.get("creds", {})["tkn"]}`,
    },
    credentials: "include",
  }).then((response) => response.json());
};

export const getUpcomingAppointments = () => {
  const patientId = Lockr.get("creds", {})["uid"];
  return fetch(getUpcomingAppointmentsURL(patientId), {
    method: "GET",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Lockr.get("creds", {})["tkn"]}`,
    },
    credentials: "include",
  }).then((response) => response.json());
};

export const getPastAppointments = () => {
  const patientId = Lockr.get("creds", {})["uid"];
  return fetch(getPastAppointmentsURL(patientId), {
    method: "GET",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Lockr.get("creds", {})["tkn"]}`,
    },
    credentials: "include",
  }).then((response) => response.json());
};

export const cancelAppointment = (appId) => {
  const patientId = Lockr.get("creds", {})["uid"];
  return fetch(getCancelAppointmentURL(appId), {
    method: "PUT",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Lockr.get("creds", {})["tkn"]}`,
    },
    credentials: "include",
  }).then((response) => response.json());
};

export const confirmAppointmentAttendance = (appId) => {
  return fetch(getConfirmAppointmentAttendanceURL(appId), {
    method: "PUT",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Lockr.get("creds", {})["tkn"]}`,
    },
    credentials: "include",
  }).then((response) => response.json());
};

export const rescheduleAppointment = () => {
  const appId = Lockr.get("upcomingAppointment", {})["id"];
  const clinicId = Lockr.get("cid", undefined);
  const newSlotId = Lockr.get("timeSlot", {})["slotId"];
  if (appId && clinicId && newSlotId) {
    return fetch(getRescheduleAppointmentURL(appId, newSlotId, clinicId), {
      method: "PUT",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Lockr.get("creds", {})["tkn"]}`,
      },
      credentials: "include",
    }).then((response) => response.json());
  } else {
    return new Promise((resolve, reject) => {
      throw new Error("Failed to reschedule appointment");
    });
  }
};

export const initiateForgotPasswordOTP = () => {
  const pid = Lockr.get("pidToPWDReset", undefined);
  if (pid) {
    return fetch(getForgotPasswordURL(pid), {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  } else {
    return new Promise((resolve, reject) => {
      throw new Error("Failed to reset password");
    });
  }
};

export const forgotPasswordOTPVerification = (otpValue) => {
  const pid = Lockr.get("pidToPWDReset", undefined);
  const newToken = Lockr.get("ttkn", undefined);
  if (pid && newToken) {
    const postData = {
      tid: newToken,
      otp: otpValue,
    };
    return fetch(getForgotPasswordOTPVerifyURL(pid), {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
      credentials: "include",
    }).then((response) => response.json());
  } else {
    return new Promise((resolve, reject) => {
      throw new Error("Failed to verify OTP");
    });
  }
};

export const setupPassword = (pwd) => {
  const pid = Lockr.get("pidToPWDReset", undefined);
  const newToken = Lockr.get("ttkn", undefined);
  if (pid && newToken) {
    const postData = {
      tid: newToken,
      patientId: pid,
      password: pwd,
    };
    return fetch(getPasswordSetupURL(), {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }).then((response) => response.json());
  } else {
    return new Promise((resolve, reject) => {
      throw new Error("Failed to set password");
    });
  }
};

// FE API
const FE_BASE_URL = window.location.origin.split("/")[2];
const TRANSPORT_PROTOCOL = window.location.origin.split("/")[0];
export const getURLDependency = (relativePath) => {
  return new URL(
    `${TRANSPORT_PROTOCOL}//${FE_BASE_URL}${relativePath}`,
    import.meta.url
  );
};

export const postContactUsData = (name, email, phone, message) => {
  return fetch(getContactUsUrl(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      email: email,
      phone: phone,
      message: message,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return { isSuccess: true, data: data, error: "" };
    })
    .catch((error) => {
      console.log(data);
      return { isSuccess: false, data: "", error: error };
    });
};

export const getAppointmentDetails = (appointmentId, token) => {
  return fetch(getAppointmentDetailsURL(appointmentId), {
    method: "GET",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "include",
  }).then((response) => response.json());
};

/**
 * The following functions aims to redirect the user to
 * start of the book appointment flow.
 * Example: Search engines might recommend an interim page such as confirmation
 * which doesn't make sense for a user, they rather start at the book appointment flow.
 */
const redirectToBookingStart = () => {
  const currentUrl = window.location.href;
  const state = Lockr.getAll();
  console.log(currentUrl);
  console.log(state);
  if (
    state.length === 0 &&
    (currentUrl.includes("ba-complete.html") ||
      currentUrl.includes("ba-confirm.html") ||
      currentUrl.includes("ba-confirm-registered.html") ||
      currentUrl.includes("ba-data.html") ||
      currentUrl.includes("ba-email.html") ||
      currentUrl.includes("ba-name.html") ||
      currentUrl.includes("ba-otp.html") ||
      currentUrl.includes("ba-pconcern.html") ||
      currentUrl.includes("ba-phone.html") ||
      currentUrl.includes("ba-time.html") ||
      currentUrl.includes("ba-view-appointment.html"))
  ) {
    console.log("redirecting...");
    window.location.href = getURLDependency("/book-appointment.html");
  }
};
redirectToBookingStart();
